<template>
  <div v-show="!loading" class="container pd100">
    <van-cell title="订单信息" />
    <div class="cell-sub mb10">
      <div class="cell-sub-p">
        <div class="cell-sub-p-hd mr5">活动名称：</div>
        <div class="cell-sub-p-bd">{{ detail.meeting_name }}</div>
      </div>
      <div class="cell-sub-p">
        <div class="cell-sub-p-hd mr5">开始时间：</div>
        <div class="cell-sub-p-bd">{{ detail.meeting_start_date }}</div>
      </div>
      <div class="cell-sub-p">
        <div class="cell-sub-p-hd mr5">结束时间：</div>
        <div class="cell-sub-p-bd">{{ detail.meeting_end_date }}</div>
      </div>
    </div>

    <van-cell title="票务选择" />
    <div class="cell-sub mb10">
      <van-radio-group :value="typeId">
        <div v-for="(item,index) in ticketList" :key="item.type_id" class="ticket-item" @click="onChange(item)">
          <div class="ticket-item-price">￥ {{ item.price }}</div>
          <div class="ticket-item-name">{{ item.type_name }}</div>
          <van-radio :name="item.type_id" />
        </div>
      </van-radio-group>
    </div>
    <div v-for="(it,j) in formList" :key="j">
        <van-cell :title="`报名人信息 (${j+1})`" />
        <div class="bgf mb10 info">
        <div class="form">
            <i-field
            v-for="(item,index) in it"
            :key="index"
            :index="index"
            :parentIndex="j"
            :field="item"
            :type="item.filedValType"
            :name="item.filedDataName"
            :label="item.filedName"
            :placeholder="item.filedPlaceholder"
            :val="item.value"
            :vals="item.values"
            @change="onFieldChange"
            />
        </div>
        </div>
    </div>
    <div class="affix-bar">
      <van-button
        class="affix-bar-btn"
        block
        type="primary"
        @click="onSubmit"
      >确认报名</van-button>
    </div>
    <copyright v-show='!loading' />
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Field, Stepper, RadioGroup, Radio } from 'vant'
import { isEmpty,isPhone } from '@/common/validate'
import { wxPay } from '@/common/wx'
import IField from '@/components/Field'
import Copyright from '@/components/Copyright'

export default {
  name: 'EventsSubmit',
  components: {
    [Stepper.name]: Stepper,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Field.name]: Field,
    IField,
    Copyright
  },
  mixins: [PageMixin],
  data() {
    return {
      banner: [],
      detail: {},
      ticketList: [],
      typeId: '',
      price:'',
      countMax: 10,
      count: 1,
      username: '',
      phone: '',
      form: [],
      formList: [],
      loading:false,
      can_repeat_purchase:0
    }
  },
  computed: {
    totalPrice() {
      const price = (this.price * 100 * this.count)/100
      return (price).toFixed(2)
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.loading = true
      this.$toast.loading({
        mask: true,
        message: '加载中...',
        duration: 0
      })
      this.$api.events_book({ meeting_id: this.$route.query.meeting_id }).then(res => {
        this.detail = res.data
        this.banner = [{ id: '111', image: res.data.meeting_cover }]
        this.ticketList = res.data.meeting_ticket_type
        this.typeId = res.data.meeting_ticket_type ? res.data.meeting_ticket_type[0].type_id : ''
        this.can_repeat_purchase = res.data.meeting_ticket_type ? res.data.meeting_ticket_type[0].can_repeat_purchase : 0
        this.price = res.data.meeting_ticket_type ? res.data.meeting_ticket_type[0].price : ''
        let form = []
        res.data.meeting_form.forEach(item => {
          const required = item.filedRequired
          let value = ''
          let values = []
          if (item.filedValType === 'select') {
            value = item.filedDataSelect[0]
            values = item.filedDataSelect
          } else if (item.filedValType === 'radio') {
            value = item.filedDataRadio[0]
            values = item.filedDataRadio
          } else {
            value = ''
          }
          form.push({
            ...item,
            required,
            value,
            values
          })
        })
        this.form = form
        // 双向绑定
        this.onChange(res.data.meeting_ticket_type[0])
        this.$toast.clear()
        this.loading = false
      })
    },
    onFieldChange({ index, value, parentIndex }) {
      this.formList[parentIndex][index].value = value
    },
    formValidate(form,ind) {
      for (let index = 0; index < form.length; index++) {
        if (form[index].required && isEmpty(form[index].value)) {
          this.$toast(`请输入报名人(${ind+1})的${form[index].filedName}`)
          return false
        }
        if(form[index].filedDataName == 'cellphone' && !isPhone(form[index].value)){
          this.$toast(`报名人(${ind+1})的手机号填写不正确~`)
          return false
        }
      }
      return true
    },
    onChange(item) {
      this.typeId = item.type_id
      this.price = item.price
      this.count = 1
      this.can_repeat_purchase = item.can_repeat_purchase
      switch(item.type_name){
        case 'VIP贵宾票（含行业晚宴席位一个、两顿午餐、VIP座位）':
          this.handleChange(1)
          break;
        case '单人票':
          this.handleChange(1)
          break;
        case '双人票':
          this.handleChange(2)
          break;
        case '三人票':
          this.handleChange(3)
          break;
        case '四人票':
          this.handleChange(4)
          break;
        case '五人票':
          this.handleChange(5)
          break;
        case '十人票':
          this.handleChange(10)
          break;
        default:
          console.log('未知')
      }
    },
    onSubmit() {
      let formArr = []
      for(let i=0; i<this.formList.length; i++){
          if(this.formValidate(this.formList[i],i)){
              formArr.push(this.formList[i].map(item => ({ [item.filedDataName]: item.value })))
          }else{
              return false
          }
      }
      if(this.isRepeat(formArr)){
        return false
      }
      const params = {
          event_id: this.$route.query.meeting_id,
          type_id: this.typeId,
          ticket_count: this.count,
          ticket_info: formArr
      }
      this.$toast.loading({
          mask: true,
          message: '报名中...',
          duration: 0
      })
      this.$api.ruiyi_ace_payment(params)
          .then(res => {
            this.$toast.clear()
            // 免费
            if (!res.data.need_pay) {
                this.$dialog.alert({
                title: '活动报名成功',
                message: `活动单号：${res.data.out_trade_no}`,
                confirmButtonText: '去查看'
                })
                .then(() => {
                    // on close
                    // 去查看活动票据
                    this.$router.replace({
                    path: `/events/ticket?ticket_id=${res.data.ticket_id}`
                    })
                })
            }else{
              localStorage.setItem('money',Number(this.totalPrice)*100)
              this.$router.push({path:'/payCenter',query:{toPath:`/events/ticket?ticket_id=${res.data.ticket_id}`,out_trade_no:res.data.out_trade_no}})
            }
          })
          .catch(err => {
          this.$toast.fail('活动报名失败')
          console.error(err)
          })
    },
    handleChange(e){
        let arr = []
        // 双向绑定问题
        for(let i=0; i<e; i++){
            let formArr = []
            this.form.forEach(item=>{
                formArr.push({...item})
            })
            arr.push([...formArr])
        }
        this.formList = arr
    },
    // 判断手机号重复
    isRepeat(arr){
      let ary = []
      let repeat = false
      arr.forEach(item=>{
        item.forEach(it=>{
          if(it.cellphone){
            ary.push(it.cellphone)
          }
        })
      })
      let s = ary.join(",")+","
      for(var i=0;i<ary.length;i++) {
        if(s.replace(ary[i]+",","").indexOf(ary[i]+",")>-1) {
          this.$toast(`报名人 (${i+1}) 的手机号有重复~`)
          repeat = true
          break;
        }
      }
      return repeat
    }
  }
}
</script>

<style lang="less" scoped>

  .cell-sub{
      background: #fff;
      padding: 10px 15px;
  }

  .cell-sub-p{
      display: flex;
      margin-bottom: 6px;
      &-hd{
          color: #333;
      }
      &-bd{
          color: #666;
      }
  }

  .ticket-item{
      display: flex;
      align-items: center;
      height: 44px;
      &-price{
          color: #00DE93;
          min-width: 100px;
      }
      &-name{
          flex: 1;
      }
  }

  .signup{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .total{
          color: #00DE93;
      }
  }

  .info{

  }
</style>

